import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, VStack, useColorMode, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiSend } from "react-icons/fi"
const ContactButton = () => {
    const { colorMode } = useColorMode()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [errorName, setErrorName] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorEmailRE, setErrorEmailRE] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const reEmail = new RegExp(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
    )
    const closeModal = () => {
        onClose()
        setName("")
        setEmail("")
        setMessage("")
        setErrorEmail(false)
        setErrorEmailRE(false)
        setErrorName(false)
        setErrorMessage(false)
        onClose()
    }
    const handleNameChange = (e) => {
        setName(e.target.value)
        setErrorName(false)
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        setErrorEmail(false)
        setErrorEmailRE(false)
    }
    const handleMessageChange = (e) => {
        setMessage(e.target.value)
        setErrorMessage(false)
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault()
        if (name.trim() === "") {
            setErrorName(true)
        }
        if (email.trim() === "") {
            setErrorEmail(true)
        }
        if (!reEmail.test(email)) {
            setErrorEmailRE(true)
        }
        if (message.trim() === "") {
            setErrorMessage(true)
        }
        if (name.trim() !== "" && email.trim() !== "" && reEmail.test(email) && message.trim() !== "") {
            setLoading(true)
            const response = await fetch("https://mailer-for-portfolio.vercel.app/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: name,
                    from: email,
                    message: message,
                }),
            })
            const data = await response.json()
            if (data.success) {
                toast({
                    title: "Message sent successfully",
                    description: "Prasoon will get back to you soon.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                setName("")
                setEmail("")
                setMessage("")
                onClose()
            } else {
                toast({
                    title: "Error",
                    description: "Something went wrong. Try Again.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
            }
            setLoading(false)

        }


    }
    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                scrollBehavior='inside'
                onClose={closeModal}
                isOpen={isOpen}
                isCentered
                motionPreset="slideInBottom"
                size={{ base: "xs", lg: "sm" }}>
                <ModalOverlay
                    backdropFilter='blur(3px)' />
                <ModalContent
                    bg={colorMode === "dark" ? "#09090B" : "#f5f5f5"}
                >
                    <ModalHeader>Send me a message</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <FormControl isInvalid={errorName}>
                                <FormLabel>Name</FormLabel>
                                <Input type='text' placeholder="Enter your name." onChange={handleNameChange} value={name} />
                                {errorName && <FormErrorMessage>Name is required.</FormErrorMessage>}
                            </FormControl>
                            <FormControl isInvalid={errorEmail || errorEmailRE}>
                                <FormLabel>Email address</FormLabel>
                                <Input type='email' placeholder="Enter your email." onChange={handleEmailChange} value={email} />
                                {(errorEmail || errorEmailRE) && <FormErrorMessage>{errorEmail ? <>Email is required.</> : <>Enter a valid email.</>}</FormErrorMessage>}
                            </FormControl>
                            <FormControl isInvalid={errorMessage}>
                                <FormLabel>Message</FormLabel>
                                <Textarea placeholder="Let the words flow..." onChange={handleMessageChange} value={message} />
                                {errorMessage && <FormErrorMessage>Message is required.</FormErrorMessage>}
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            // border="1px"
                            borderRadius="10px"
                            colorScheme="whatsapp"
                            // variant="outline"
                            isLoading={loading}
                            loadingText="Sending Message..."
                            leftIcon={<FiSend />}
                            onClick={handleFormSubmit}>
                            Send Message
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
            <Button
                onClick={onOpen}
                border="1px"
                borderRadius="10px"
                borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                target="_blank"
                bg="none"
                backdropFilter={"blur(5px)"}
                _hover={{
                    textDecoration: "none",
                    bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                }}
                leftIcon={<FiSend />}
            >
                Contact
            </Button>
        </>
    )
}

export default ContactButton