import React, { useEffect, useContext } from 'react'
import Navbar from "../components/Navbar"
import { VStack, Heading } from '@chakra-ui/react'
import Section from '../components/Section'
import Volunteer from '../components/Volunteer/Volunteer'
import { volunteering } from '../data/volunteering'
import Loader from '../components/Loader/Loader'
import loaderContext from '../context/loaderContext'
const Volunteering = () => {
    const context = useContext(loaderContext)
    const { loading, setLoading } = context

    useEffect(() => {
        document.title = "Volunteering | Prasoon Soni"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [setLoading])
    return (
        <div>
            {loading ? <Loader /> : <>
                <Navbar />
                <Section id="volunteering">
                    <VStack align="stretch" spacing={4} p={4}>
                        <Heading w="full">Volunteering</Heading>
                        {volunteering.map((exp, i) => {
                            return (
                                <Volunteer
                                    key={exp.id}
                                    role={exp.role}
                                    company={exp.company}
                                    type={exp.type}
                                    location={exp.location}
                                    logo={exp.logo}
                                    date={exp.date}
                                    description={exp.description}
                                    // aos={((i + 1) * 100) + 500}
                                    // anchor="#experience"
                                    delay={(i + 1) / 3}
                                />
                            )
                        })}
                    </VStack>
                </Section>
            </>}
        </div >
    )
}

export default Volunteering