import React, { useEffect, useContext } from 'react'
import Navbar from "../components/Navbar"
import { VStack, Text, Heading, Wrap, useColorMode } from '@chakra-ui/react'
import Section from '../components/Section'
import { programming_languages, technologies_frameworks, developer_tools, soft_skills, platforms } from "../data/skills";
import SkillButton from "../components/Skills/SkillButton"
import { motion } from 'framer-motion'
import Loader from '../components/Loader/Loader';
import loaderContext from '../context/loaderContext';
const Skills = () => {
    const { colorMode } = useColorMode()
    const context = useContext(loaderContext)
    const { loading, setLoading } = context

    useEffect(() => {
        document.title = "Skills | Prasoon Soni"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [setLoading])
    return (
        <div>
            {loading ? <Loader /> : <>
                <Navbar />
                <Section id="skills">
                    <VStack align="stretch" spacing={4} p={4}>
                        <Heading w="full">Skills</Heading>
                        <VStack align="stretch" spacing={4}>
                            <motion.div
                                initial={{ y: "100%", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    duration: 0.33,
                                }}>
                                <motion.div
                                    whileHover={{
                                        scale: 1.05
                                    }}>
                                    <VStack
                                        alignItems="start"
                                        border="1px"
                                        borderRadius="10px" 
                                        backdropFilter={"blur(5px)"}
                                        borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                                        p={4}>
                                        <Text fontWeight="bold">Programming Languages</Text>
                                        <Wrap>
                                            {programming_languages.map((el) => (
                                                <SkillButton key={el.id} text={el.text} icon={el.icon} />
                                            ))}
                                        </Wrap>
                                    </VStack>
                                </motion.div>
                            </motion.div>

                            <motion.div
                                initial={{ y: "100%", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    duration: 0.66,
                                }}>
                                <motion.div
                                    whileHover={{
                                        scale: 1.05
                                    }}>
                                    <VStack
                                        alignItems="start"
                                        border="1px"
                                        borderRadius="10px"
                                        borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                                        backdropFilter={"blur(5px)"}
                                        p={4}>
                                        <Text fontWeight="bold">Technologies/Frameworks</Text>
                                        <Wrap>
                                            {technologies_frameworks.map((el) => (
                                                <SkillButton key={el.id} text={el.text} icon={el.icon} />
                                            ))}
                                        </Wrap>
                                    </VStack>
                                </motion.div>
                            </motion.div>

                            <motion.div
                                initial={{ y: "100%", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    duration: 0.99,
                                }}>
                                <motion.div
                                    whileHover={{
                                        scale: 1.05
                                    }}>
                                    <VStack
                                        alignItems="start"
                                        border="1px"
                                        borderRadius="10px"
                                        borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                                        backdropFilter={"blur(5px)"}
                                        p={4}>
                                        <Text fontWeight="bold">Developer Tools</Text>
                                        <Wrap>
                                            {developer_tools.map((el) => (
                                                <SkillButton key={el.id} text={el.text} icon={el.icon} />
                                            ))}
                                        </Wrap>
                                    </VStack>
                                </motion.div>
                            </motion.div>

                            <motion.div
                                initial={{ y: "100%", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    duration: 1.32,
                                }}>
                                <motion.div
                                    whileHover={{
                                        scale: 1.05
                                    }}>
                                    <VStack
                                        alignItems="start"
                                        border="1px"
                                        borderRadius="10px"
                                        borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                                        backdropFilter={"blur(5px)"}
                                        p={4}>
                                        <Text fontWeight="bold">Platforms</Text>
                                        <Wrap>
                                            {platforms.map((el) => (
                                                <SkillButton key={el.id} text={el.text} icon={el.icon} />
                                            ))}
                                        </Wrap>
                                    </VStack>
                                </motion.div>
                            </motion.div>

                            <motion.div
                                initial={{ y: "100%", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    duration: 1.65,
                                }}>
                                <motion.div
                                    whileHover={{
                                        scale: 1.05
                                    }}>
                                    <VStack
                                        alignItems="start"
                                        border="1px"
                                        borderRadius="10px"
                                        borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                                        backdropFilter={"blur(5px)"}
                                        p={4}>
                                        <Text fontWeight="bold">Soft Skills</Text>
                                        <Wrap>
                                            {soft_skills.map((el) => (
                                                <SkillButton key={el.id} text={el.text} icon={el.icon} />
                                            ))}
                                        </Wrap>
                                    </VStack>
                                </motion.div>
                            </motion.div>

                        </VStack>
                    </VStack>
                </Section>
            </>}
        </div >
    )
}

export default Skills