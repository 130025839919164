import React, { useEffect, useContext } from 'react'
import Navbar from "../components/Navbar"
import { VStack, Heading } from '@chakra-ui/react'
import Section from '../components/Section'
import Company from '../components/Experience/Company'
import { experience } from '../data/experience'
import Loader from '../components/Loader/Loader'
import loaderContext from '../context/loaderContext'
const Experience = () => {
    const context = useContext(loaderContext)
    const { loading, setLoading } = context

    useEffect(() => {
        document.title = "Experience | Prasoon Soni"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [setLoading])
    return (
        <div>
            {loading ? <Loader /> : <>
                <Navbar />
                <Section id="experience">
                    <VStack align="stretch" spacing={4} p={4}>
                        <Heading w="full">Experience</Heading>
                        {experience.map((exp, i) => {
                            return (
                                <Company
                                    key={exp.id}
                                    role={exp.role}
                                    company={exp.company}
                                    type={exp.type}
                                    location={exp.location}
                                    logo={exp.logo}
                                    date={exp.date}
                                    description={exp.description}
                                    // aos={((i + 1) * 100) + 500}
                                    // anchor="#experience"
                                    delay={(i + 1) / 3}
                                />
                            )
                        })}
                    </VStack>
                </Section>
            </>}
        </div >
    )
}

export default Experience