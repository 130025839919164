import { Image } from '@chakra-ui/react'
import React from 'react'

const ProfileImage = () => {
    return (
        <Image
            filter="grayscale(100%)"
            transition="0.5s"
            _hover={{
                filter: "grayscale(0%)"
            }}
            src='./profile.jpg'
            borderRadius="full"
            boxSize="175px"
        />
    )
}

export default ProfileImage
