import { HStack, Stack, Image, Text, VStack, useColorMode, Button } from '@chakra-ui/react'
import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { FiCalendar } from "react-icons/fi"

const Volunteer = (props) => {
    const { colorMode } = useColorMode()
    const [show, setShow] = useState(false)
    return (
        <motion.div
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
                type: "spring",
                duration: props.delay,
            }}
        >
            <motion.div
                whileHover={{
                    scale: 1.05
                }}>
                <Stack
                    direction={{ base: "column", md: "row", }}>
                    <HStack alignItems="start"
                        border="1px"
                        borderRadius="10px"
                        borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                        backdropFilter={"blur(5px)"}
                        width="100%"
                        p={4}>
                        <Image
                            mt={1}
                            style={{ height: "60px", width: "60px" }}
                            borderRadius="10px"
                            src={props.logo} />
                        <VStack
                            alignItems="left"
                            spacing={0}
                            p={0}>
                            <Text
                                fontSize="md"
                                fontWeight="bold">
                                {props.role}
                            </Text>
                            <Text
                                fontSize="sm"
                                fontWeight="semibold">
                                {props.company}
                            </Text>
                            <Text
                                color={colorMode === "dark" ? "#939595" : "#848484"}
                                fontSize="sm" display="flex"
                                alignItems="center">
                                <FiCalendar />&nbsp;{props.date}
                            </Text>
                            {props.description.length > 0 && <Button variant="link" w="fit-content" size="sm" colorScheme='facebook' _active={{ color: "none" }} onClick={() => setShow(!show)}>{show ? "Hide Details" : "Show Details"}</Button>}
                            {show && props.description.map((d, i) => {
                                return (
                                    <Text fontSize="sm" key={i}>
                                        • {d}
                                    </Text>
                                )
                            })}
                        </VStack>
                    </HStack>
                </Stack>
            </motion.div>
        </motion.div>
    )
}

export default Volunteer