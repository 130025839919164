// importing logos
import icicict from '../images/logos/icicict.png'
import icecct from '../images/logos/icecct.png'
import icaccs from "../images/logos/icaccs.png"
// importing certificates
import icicict_cert from '../images/certificates/gestfanz-researchpaper.png'
import icecct_cert from '../images/certificates/safechain-researchpaper.jpg'
import icaccs_cert from "../images/certificates/nlp-researchpaper.png"
import icomputer_cert from "../images/certificates/organshield.png"

const publications = [
    {
        id: 3,
        title: "Blockchain Based Organ Donation Platform: Defeating Trafficking and Ensuring Transparency",
        description: "iCOMPUTER-2023 · IRJASH",
        certificate: icomputer_cert,
        date: "Mar 2023",
        logo: "",
        link: "https://rspsciencehub.com/article_23847.html"
    },
    {
        id: 0,
        title: "Automated Test Case Generation Using T5 and GPT-3",
        description: "ICACCS-2023 · IEEE",
        certificate: icaccs_cert,
        date: "Mar 2023",
        logo: icaccs,
        link: "https://ieeexplore.ieee.org/document/10112971"
    },
    {
        id: 1,
        title: "A Novel Approach for Secured Decentralised Data Protection Vault",
        description: "ICECCT-2023 · IEEE",
        certificate: icecct_cert,
        date: "Feb 2023",
        logo: icecct,
        link: "https://ieeexplore.ieee.org/document/10179812"
    },
    {
        id: 2,
        title: "A Human Machine Interactive Model for Fan Automation",
        description: "ICICICT-2022 · IEEE",
        certificate: icicict_cert,
        date: "Aug 2022",
        logo: icicict,
        link: "https://ieeexplore.ieee.org/document/9917625"
    },

]

export default publications
