import React, { useEffect, useContext } from 'react'
import Navbar from "../components/Navbar"
import { VStack, Text, Heading } from '@chakra-ui/react'
import Section from '../components/Section'
import loaderContext from "../context/loaderContext";
import Loader from "../components/Loader/Loader"
import about from '../data/about';
const About = () => {
    const context = useContext(loaderContext)
    const { loading, setLoading } = context

    useEffect(() => {
        document.title = "About | Prasoon Soni"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [setLoading])
    return (
        <div>
            {loading ? <Loader /> : <>
                <Navbar />
                <Section>
                    <VStack align="stretch" spacing={4} p={4}>
                        <Heading w="full">About</Heading>
                        {about.map((a) => {
                            return (
                                <Text textAlign="justify">
                                    {a}
                                </Text>
                            )
                        })}
                    </VStack>
                </Section>
            </>}
        </div >
    )
}

export default About