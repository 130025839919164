import React, { useEffect, useContext } from 'react'
import Navbar from "../components/Navbar"
import { VStack, Heading } from '@chakra-ui/react'
import Section from '../components/Section'
import Project from '../components/Projects/Project'
import { projects } from "../data/projects"
import Loader from '../components/Loader/Loader'
import loaderContext from '../context/loaderContext'
const Projects = () => {
    const context = useContext(loaderContext)
    const { loading, setLoading } = context

    useEffect(() => {
        document.title = "Projects | Prasoon Soni"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [setLoading])
    return (
        <div>
            {loading ? < Loader /> : <>
                <Navbar />
                <Section id="projects">
                    <VStack align="stretch" spacing={4} p={4}>
                        <Heading w="full">Projects</Heading>
                        {projects.map((project, i) => {
                            return (
                                <Project
                                    name={project.title}
                                    tech={project.tech}
                                    description={project.subtitle}
                                    bg={project.color2}
                                    emoji={project.emoji}
                                    github={project.repo}
                                    website={project.link}
                                    // delay={((i + 1) * 500) + 500}
                                    delay={(i + 1) / 6}
                                // anchor="#projects"
                                />
                            )
                        })}
                    </VStack>
                </Section>
            </>}
        </div >
    )
}

export default Projects