import React, { useEffect, useContext } from 'react'
import Navbar from "../components/Navbar"
import { VStack, Heading } from '@chakra-ui/react'
import Section from '../components/Section'
import { education } from '../data/education'
import EducationItem from '../components/Education/EducationItem'
import Loader from '../components/Loader/Loader'
import loaderContext from '../context/loaderContext'
const Education = () => {
    const context = useContext(loaderContext)
    const { loading, setLoading } = context

    useEffect(() => {
        document.title = "Education | Prasoon Soni"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [setLoading])
    return (
        <div>
            {loading ? <Loader /> : <>
                <Navbar />
                <Section id="education">
                    <VStack align="stretch" spacing={4} p={4}>
                        <Heading w="full">Education</Heading>
                        {education.map((edu, i) => {
                            return (
                                <EducationItem
                                    key={edu.id}
                                    logo={edu.logo}
                                    name={edu.name}
                                    degree={edu.degree}
                                    stream={edu.stream}
                                    date={edu.date}
                                    location={edu.location}
                                    grade={edu.grade}
                                    // aos={((i+1)*100)+500}
                                    // anchor="#education"
                                    delay={(i + 1) / 3}
                                />
                            )
                        })}
                    </VStack>
                </Section>
            </>}
        </div >
    )
}

export default Education