import { HStack, Text, Link } from '@chakra-ui/react'
import React from 'react'

const Footer = () => {
    return (
        <HStack p={3} justifyContent="center">
            <Text
                color="gray">
                Copyright © {new Date().getFullYear()} <Link
                    href="https://prasoonsoni.com"
                    target="_blank">
                    Prasoon Soni
                </Link>
            </Text>
        </HStack>
    )
}

export default Footer