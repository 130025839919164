import { Button, Flex, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Spacer, useColorMode } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { FiAward, FiBook, FiBookOpen, FiBriefcase, FiCode, FiHome, FiImage, FiMenu, FiMoon, FiSettings, FiSun, FiTool, FiUser, FiX } from 'react-icons/fi'
import { useLocation, useNavigate } from "react-router-dom"
import backgroundContext from '../context/backgroundContext'
const ToggleTheme = () => {
    const MenuItemButton = (p) => {
        return (
            <MenuItem
                icon={p.icon}
                onClick={() => navigate(p.navigate)}
                bg={!p.isFocused ? (colorMode === "dark" ? "#09090B" : "#f5f5f5") : (colorMode === "dark" ? "#2a2b2d" : "#e2e8f0")}
                _hover={{
                    textDecoration: "none",
                    bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                }}>
                {p.name}
            </MenuItem>
        )
    }
    const SettingMenuItemButton = (p) => {
        return (
            <MenuItem
                icon={p.icon}
                onClick={p.onClick}
                bg={!p.isFocused ? (colorMode === "dark" ? "#09090B" : "#f5f5f5") : (colorMode === "dark" ? "#2a2b2d" : "#e2e8f0")}
                _hover={{
                    textDecoration: "none",
                    bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                }}>
                {p.name}
            </MenuItem>
        )
    }
    const { colorMode, toggleColorMode } = useColorMode()
    const context = useContext(backgroundContext)
    const { showBackground, setShowBackground } = context
    const navigate = useNavigate()
    const location = useLocation()
    const [pathname] = useState(location.pathname)

    return (
        <Flex w="full">
            <Menu isLazy>
                {({ isOpen }) => (
                    <>
                        <MenuButton m={2} p={0} borderRadius={5} bg={isOpen && (colorMode === "dark" ? "#2a2b2d" : "#e2e8f0")} _hover={{
                            bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0",

                        }}>
                            <Button
                                fontSize="xl"
                                p={0}
                                bg="none"

                            >
                                {isOpen ? <FiX /> : <FiMenu />}
                            </Button>
                        </MenuButton>
                        <MenuList
                            bg={colorMode === "dark" ? "#09090B" : "#f5f5f5"}>
                            <MenuItemButton name="Home" icon={<FiHome />} navigate="/" isFocused={pathname === "/"} />
                            <MenuItemButton name="About" icon={<FiUser />} navigate="/about" isFocused={pathname === "/about"} />
                            <MenuItemButton name="Education" icon={<FiBookOpen />} navigate="/education" isFocused={pathname === "/education"} />
                            <MenuItemButton name="Skills" icon={<FiTool />} navigate="/skills" isFocused={pathname === "/skills"} />
                            <MenuItemButton name="Experience" icon={<FiBriefcase />} navigate="/experience" isFocused={pathname === "/experience"} />
                            <MenuItemButton name="Projects" icon={<FiCode />} navigate="/projects" isFocused={pathname === "/projects"} />
                            <MenuItemButton name="Achievements" icon={<FiAward />} navigate="/achievements" isFocused={pathname === "/achievements"} />
                            <MenuItemButton name="Publications" icon={<FiBook />} navigate="/publications" isFocused={pathname === "/publications"} />
                            {/* <MenuItemButton name="Volunteering" icon={<FiSmile />} navigate="/volunteering" isFocused={pathname === "/volunteering"} /> */}
                        </MenuList>
                    </>
                )}

            </Menu>

            <Spacer />

            <Menu isLazy>
                {({ isOpen }) => (
                    <>
                        <MenuButton m={2} p={0} borderRadius={5} bg={isOpen && (colorMode === "dark" ? "#2a2b2d" : "#e2e8f0")} _hover={{
                            bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0",

                        }}>
                            <Button
                                fontSize="xl"
                                p={0}
                                bg="none"

                            >
                                <FiSettings />
                            </Button>
                        </MenuButton>
                        <MenuList
                            bg={colorMode === "dark" ? "#09090B" : "#f5f5f5"}>
                            <MenuGroup title="Theme">
                                <SettingMenuItemButton name="Dark Mode" icon={<FiMoon />} isFocused={colorMode === "dark"} onClick={toggleColorMode} />
                                <SettingMenuItemButton name="Light Mode" icon={<FiSun />} isFocused={colorMode === "light"} onClick={toggleColorMode} />
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup title="Background">
                                <SettingMenuItemButton name={`Animation ${showBackground ? "On" : "Off"}`} icon={<FiImage />} isFocused={showBackground} onClick={() => setShowBackground(!showBackground)} />
                            </MenuGroup>
                        </MenuList>
                    </>
                )}

            </Menu>
        </Flex >
    )
}

export default ToggleTheme