import React, { useEffect, useContext } from 'react'
import Navbar from "../components/Navbar"
import { VStack, Heading } from '@chakra-ui/react'
import Section from '../components/Section'
import publications from '../data/publications'
import Publication from '../components/Publications/Publication'
import Loader from '../components/Loader/Loader'
import loaderContext from '../context/loaderContext'
const Publications = () => {
    const context = useContext(loaderContext)
    const { loading, setLoading } = context

    useEffect(() => {
        document.title = "Publications | Prasoon Soni"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [setLoading])
    return (
        <div>
            {loading ? <Loader /> : <>
                <Navbar />
                <Section id="publication">
                    <VStack align="stretch" spacing={4} p={4}>
                        <Heading w="full">Publications</Heading>
                        {publications.map((achievement, i) => {
                            return <Publication
                                key={achievement.id}
                                title={achievement.title}
                                description={achievement.description}
                                logo={achievement.logo}
                                certificate={achievement.certificate}
                                date={achievement.date}
                                link={achievement.link}
                                // aos={((i + 1) * 100) + 500}
                                // anchor="#publication"
                                delay={(i + 1) / 3}
                            />
                        })}

                    </VStack>
                </Section>
            </>}
        </div >
    )
}

export default Publications