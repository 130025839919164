import { Button, Link, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { motion } from "framer-motion";

const SocialButton = ({ social }) => {
    const { colorMode } = useColorMode()
    return (
        <motion.div
            whileHover={{
                y:-2
            }}>
            <Button
                as={Link}
                href={social.url}
                target="_blank"
                fontSize="xl"
                bg="none"
                backdropFilter={"blur(5px)"}
                p="0"
                _hover={{
                    bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0",
                    color: social.color
                }}>
                {social.icon}
            </Button>
        </motion.div>
    )
}

export default SocialButton