import { HStack, Stack, Image, Text, VStack, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { motion } from 'framer-motion'
import { FiCalendar, FiPercent } from "react-icons/fi"
// import { MdOutlineLocationOn } from "react-icons/md"
const EducationItem = (props) => {
    const { colorMode } = useColorMode()
    return (
        <motion.div
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
                type: "spring",
                duration: props.delay,
            }}
        >
            <motion.div
                whileHover={{
                    scale: 1.05,
                }}>
                <Stack
                    direction={{ base: "column", md: "row", }}
                    backdropFilter={"blur(5px)"}>
                    <HStack alignItems="start"
                        border="1px"
                        borderRadius="10px"
                        borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                        width="100%"
                        p={4}>
                        <Image
                            mt={1}
                            style={{ height: "60px", width: "60px" }}
                            borderRadius="10px"
                            src={props.logo} />
                        <VStack
                            alignItems="left"
                            spacing={0}
                            p={0}>
                            <Text
                                fontSize="md"
                                fontWeight="bold">
                                {props.name}
                            </Text>
                            <Text
                                fontSize="sm"
                                fontWeight="semibold">
                                {props.degree} · {props.stream}
                            </Text>
                            <Text
                                color={colorMode === "dark" ? "#939595" : "#848484"}
                                display="flex"
                                alignItems="center"
                                fontSize="sm">
                                <FiCalendar />&nbsp;{props.date}
                            </Text>
                            {/* <Text
                                color={colorMode === "dark" ? "#939595" : "#848484"}
                                display="flex"
                                alignItems="center"
                                fontSize="sm">
                                <MdOutlineLocationOn />&nbsp;{props.location}
                            </Text> */}
                            <Text
                                color={colorMode === "dark" ? "#939595" : "#848484"}
                                fontSize="sm"
                                display="flex"
                                alignItems="center">
                                <FiPercent />&nbsp;Grade: {props.grade}
                            </Text>
                        </VStack>
                    </HStack>
                </Stack>
            </motion.div>
        </motion.div>
    )
}

export default EducationItem