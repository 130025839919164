import React from 'react';
import TextTransition, { presets } from 'react-text-transition';

const TEXTS = ["Software Engineer", "Android Developer", "MERN Stack Developer", "SIH'22 Winner 🏆",];
const TextAnimation = () => {
    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            1500,
        );
        return () => clearTimeout(intervalId);
    }, []);
    return (
        <TextTransition
            direction='up'
            inline={true}
            springConfig={presets.gentle}>
            {TEXTS[index % TEXTS.length]}
        </TextTransition>

    )
}

export default TextAnimation