import React from 'react'
import { VStack, useColorMode } from '@chakra-ui/react'
const Loader = () => {
    const { colorMode } = useColorMode()
    return (
        <VStack h="100vh" alignItems="center" justifyContent="center" w="100vw">
            <style>
                {`
                .path {
                    stroke-dasharray: 15709.2578125;
                    stroke-dashoffset: 15709.2578125;
                    animation: dash 2s ease-out 0s forwards;
                }
                @keyframes dash {
                    0% {
                        stroke-dashoffset: 15709.2578125;
                        fill: transparent;
                    }

                    80% {
                        fill: transparent;
                    }

                    100% {
                        stroke-dashoffset: 0;
                        fill: ${colorMode === "dark" ? "#dadbdb" : "#393e46"};
                }}
                #logo {
                    animation: fill 0.5s ease forwards 2s;
                    height:50px
                }

                #p {
                    stroke-dasharray: 353.1450500488281;
                    stroke-dashoffset: 353.1450500488281;
                    animation: line-anim 2s ease forwards;
                }

                #r {
                    stroke-dasharray: 437;
                    stroke-dashoffset: 437;
                    animation: line-anim 2s ease forwards 0.3s;
                }

                #a {
                    stroke-dasharray: 394.83013916015625;
                    stroke-dashoffset: 394.83013916015625;
                    animation: line-anim 2s ease forwards 0.6s;
                }

                #s {
                    stroke-dasharray: 405.12;
                    stroke-dashoffset: 405.12;
                    animation: line-anim 2s ease forwards 0.9s;
                }

                #o1 {
                    stroke-dasharray: 353.1450500488281;
                    stroke-dashoffset: 353.1450500488281;
                    animation: line-anim 2s ease forwards 1.2s;
                }

                #o2 {
                    stroke-dasharray: 353.1450500488281;
                    stroke-dashoffset: 353.1450500488281;
                    animation: line-anim 2s ease forwards 1.5s;
                }

                #n {
                    stroke-dasharray: 480;
                    stroke-dashoffset: 480;
                    animation: line-anim 2s ease forwards 1.8s;
                }

                @keyframes line-anim {
                    to {
                        stroke-dashoffset: 0;
                    }
                }

                @keyframes fill {
                    from {
                        fill: transparent;
                    }
                    to {
                        fill: ${colorMode === "dark" ? "#dadbdb" : "#393e46"};
                    }
                }

                    `
                }
            </style>
            
            {/* <svg version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="300"
                height="300"
                viewBox="0 0 500 500"
                fill="none">
                <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
                    <path
                        className="path"
                        stroke={colorMode === "dark" ? "#dadbdb" : "#393e46"}
                        strokeWidth="50"
                        d="M1378 4479 c-17 -9 -18 -37 -18 -364 l0 -354 738 -4 c712 -3 739 -4
                        809 -24 271 -78 391 -227 381 -473 -3 -84 -8 -105 -36 -162 -41 -83 -112 -150
                        -205 -193 -140 -65 -146 -65 -957 -65 l-730 0 0 -1165 c0 -1141 0 -1165 19
                        -1175 30 -16 682 -14 704 3 16 11 17 74 20 805 l2 793 310 -1 c421 0 612 20
                        826 90 194 64 355 157 487 284 265 254 366 616 297 1061 -84 534 -496 873
                        -1147 944 -129 15 -1476 14 -1500 0z"
                    />
                </g>
            </svg> */}
            <svg id="logo" width="416" height="62" viewBox="0 0 416 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="p"
                    d="M45.76 20.92C45.76 24.1733 45.0133 27.16 43.52 29.88C42.0267 32.5467 39.7333 34.7067 36.64 36.36C33.5467 38.0133 29.7067 38.84 25.12 38.84H16.64V59H2.95999V2.83999H25.12C29.6 2.83999 33.3867 3.61332 36.48 5.15999C39.5733 6.70666 41.8933 8.83999 43.44 11.56C44.9867 14.28 45.76 17.4 45.76 20.92ZM24.08 27.96C26.6933 27.96 28.64 27.3467 29.92 26.12C31.2 24.8933 31.84 23.16 31.84 20.92C31.84 18.68 31.2 16.9467 29.92 15.72C28.64 14.4933 26.6933 13.88 24.08 13.88H16.64V27.96H24.08Z"
                    stroke={colorMode === "dark" ? "#dadbdb" : "#393e46"} stroke-width="3" />
                <path id="r"
                    d="M85.5219 59L73.8419 37.8H70.5619V59H56.8819V2.83999H79.8419C84.2685 2.83999 88.0285 3.61332 91.1219 5.15999C94.2685 6.70666 96.6152 8.83999 98.1619 11.56C99.7085 14.2267 100.482 17.2133 100.482 20.52C100.482 24.2533 99.4152 27.5867 97.2819 30.52C95.2019 33.4533 92.1085 35.5333 88.0019 36.76L100.962 59H85.5219ZM70.5619 28.12H79.0419C81.5485 28.12 83.4152 27.5067 84.6419 26.28C85.9219 25.0533 86.5619 23.32 86.5619 21.08C86.5619 18.9467 85.9219 17.2667 84.6419 16.04C83.4152 14.8133 81.5485 14.2 79.0419 14.2H70.5619V28.12Z"
                    stroke={colorMode === "dark" ? "#dadbdb" : "#393e46"} stroke-width="3" />
                <path id="a"
                    d="M148.029 49.08H127.069L123.709 59H109.389L129.709 2.83999H145.549L165.869 59H151.389L148.029 49.08ZM144.509 38.52L137.549 17.96L130.669 38.52H144.509Z"
                    stroke={colorMode === "dark" ? "#dadbdb" : "#393e46"} stroke-width="3" />
                <path id="s"
                    d="M196.374 59.56C192.267 59.56 188.587 58.8933 185.334 57.56C182.08 56.2267 179.467 54.2533 177.494 51.64C175.574 49.0267 174.56 45.88 174.454 42.2H189.014C189.227 44.28 189.947 45.88 191.174 47C192.4 48.0667 194 48.6 195.974 48.6C198 48.6 199.6 48.1467 200.774 47.24C201.947 46.28 202.534 44.9733 202.534 43.32C202.534 41.9333 202.054 40.7867 201.094 39.88C200.187 38.9733 199.04 38.2267 197.654 37.64C196.32 37.0533 194.4 36.3867 191.894 35.64C188.267 34.52 185.307 33.4 183.014 32.28C180.72 31.16 178.747 29.5067 177.094 27.32C175.44 25.1333 174.614 22.28 174.614 18.76C174.614 13.5333 176.507 9.45332 180.294 6.51999C184.08 3.53332 189.014 2.03999 195.094 2.03999C201.28 2.03999 206.267 3.53332 210.054 6.51999C213.84 9.45332 215.867 13.56 216.134 18.84H201.334C201.227 17.0267 200.56 15.6133 199.334 14.6C198.107 13.5333 196.534 13 194.614 13C192.96 13 191.627 13.4533 190.614 14.36C189.6 15.2133 189.094 16.4667 189.094 18.12C189.094 19.9333 189.947 21.3467 191.654 22.36C193.36 23.3733 196.027 24.4667 199.654 25.64C203.28 26.8667 206.214 28.04 208.454 29.16C210.747 30.28 212.72 31.9067 214.374 34.04C216.027 36.1733 216.854 38.92 216.854 42.28C216.854 45.48 216.027 48.3867 214.374 51C212.774 53.6133 210.427 55.6933 207.334 57.24C204.24 58.7867 200.587 59.56 196.374 59.56Z"
                    stroke={colorMode === "dark" ? "#dadbdb" : "#393e46"} stroke-width="3" />
                <path id="o1"
                    d="M255.832 59.56C250.552 59.56 245.699 58.3333 241.272 55.88C236.899 53.4267 233.406 50.0133 230.792 45.64C228.232 41.2133 226.952 36.2533 226.952 30.76C226.952 25.2667 228.232 20.3333 230.792 15.96C233.406 11.5867 236.899 8.17333 241.272 5.72C245.699 3.26666 250.552 2.03999 255.832 2.03999C261.112 2.03999 265.939 3.26666 270.312 5.72C274.739 8.17333 278.206 11.5867 280.712 15.96C283.272 20.3333 284.552 25.2667 284.552 30.76C284.552 36.2533 283.272 41.2133 280.712 45.64C278.152 50.0133 274.686 53.4267 270.312 55.88C265.939 58.3333 261.112 59.56 255.832 59.56ZM255.832 47.08C260.312 47.08 263.886 45.5867 266.552 42.6C269.272 39.6133 270.633 35.6667 270.633 30.76C270.633 25.8 269.272 21.8533 266.552 18.92C263.886 15.9333 260.312 14.44 255.832 14.44C251.299 14.44 247.672 15.9067 244.952 18.84C242.286 21.7733 240.952 25.7467 240.952 30.76C240.952 35.72 242.286 39.6933 244.952 42.68C247.672 45.6133 251.299 47.08 255.832 47.08Z"
                    stroke={colorMode === "dark" ? "#dadbdb" : "#393e46"} stroke-width="3" />
                <path id="o2"
                    d="M322.723 59.56C317.443 59.56 312.59 58.3333 308.163 55.88C303.79 53.4267 300.296 50.0133 297.683 45.64C295.123 41.2133 293.843 36.2533 293.843 30.76C293.843 25.2667 295.123 20.3333 297.683 15.96C300.296 11.5867 303.79 8.17333 308.163 5.72C312.59 3.26666 317.443 2.03999 322.723 2.03999C328.003 2.03999 332.83 3.26666 337.203 5.72C341.63 8.17333 345.096 11.5867 347.603 15.96C350.163 20.3333 351.443 25.2667 351.443 30.76C351.443 36.2533 350.163 41.2133 347.603 45.64C345.043 50.0133 341.576 53.4267 337.203 55.88C332.83 58.3333 328.003 59.56 322.723 59.56ZM322.723 47.08C327.203 47.08 330.776 45.5867 333.443 42.6C336.163 39.6133 337.523 35.6667 337.523 30.76C337.523 25.8 336.163 21.8533 333.443 18.92C330.776 15.9333 327.203 14.44 322.723 14.44C318.19 14.44 314.563 15.9067 311.843 18.84C309.176 21.7733 307.843 25.7467 307.843 30.76C307.843 35.72 309.176 39.6933 311.843 42.68C314.563 45.6133 318.19 47.08 322.723 47.08Z"
                    stroke={colorMode === "dark" ? "#dadbdb" : "#393e46"} stroke-width="3" />
                <path id="n" d="M413.294 59H399.614L376.734 24.36V59H363.054V2.83999H376.734L399.614 37.64V2.83999H413.294V59Z"
                    stroke={colorMode === "dark" ? "#dadbdb" : "#393e46"} stroke-width="3" />
            </svg>
        </VStack>

    )
}

export default Loader