import { Button, HStack, Image, Text, VStack, useColorMode } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { FiCalendar } from "react-icons/fi"
const Company = (props) => {
    const { colorMode } = useColorMode()
    const [show, setShow] = useState(false)
    return (
        <motion.div
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
                type: "spring",
                duration: props.delay,
            }}
        >
            <motion.div
                whileHover={{
                    scale: 1.05,
                }}>
                <VStack
                    direction={{ base: "column", md: "row", }}
                    backdropFilter={"blur(5px)"}
                    border="1px"
                    borderRadius="10px"
                    borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                    width="100%"
                    alignItems={"start"}
                    p={4}>
                    <HStack alignItems="start">
                        <Image
                            mt={1}
                            style={{ height: "60px", width: "60px" }}
                            borderRadius="10px"
                            src={props.logo} />
                        <VStack
                            alignItems="left"
                            spacing={0}
                            p={0}>
                            <Text
                                fontSize="md"
                                fontWeight="bold">
                                {props.role}
                            </Text>
                            <Text
                                fontSize="sm"
                                fontWeight="semibold">
                                {props.company} · {props.type}
                            </Text>
                            <Text
                                color={colorMode === "dark" ? "#939595" : "#848484"}
                                fontSize="sm"
                                display="flex"
                                alignItems="center">
                                <FiCalendar />&nbsp;{props.date}
                                {/* &nbsp;· {props.location} */}
                            </Text>
                            {/* {props.description.length > 0 && !show && <Button variant="link" w="fit-content" size="sm" colorScheme='facebook' _active={{ color: "none" }} onClick={() => setShow(!show)}>{show ? "Hide Details" : "Show Details"}</Button>} */}
                        </VStack>
                    </HStack>
                    {props.description.length > 0 && !show && <Text fontSize={"sm"}>                        
                        {props.description[0].length > 180 ? props.description[0].substring(0, 180) + "..." : props.description[0]}
                    </Text>}
                    {show && <VStack
                        alignItems={"start"}
                        spacing={1}
                    >
                        {props.description.map((d, i) => {
                            return (
                                <Text fontSize="sm" key={i}>
                                    • {d}
                                </Text>
                            )
                        })}
                    </VStack>}
                    {props.description.length > 0 && <Button variant="link" w="fit-content" size="sm" colorScheme='facebook' _active={{ color: "none" }} onClick={() => setShow(!show)}>{show ? "Show Less" : "Show More"}</Button>}
                </VStack>
            </motion.div>
        </motion.div>
    )
}

export default Company