import { VStack, Text, HStack, Button, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from "react-router-dom"
import Navbar from "../components/Navbar"
import { FiHome } from "react-icons/fi"
import { TbError404 } from "react-icons/tb"
import Footer from '../components/Footer'
const PageNotFound = () => {
    const navigate = useNavigate()
    const { colorMode } = useColorMode()
    return (
        <VStack h="100vh" spacing={0}>
            <Navbar />
            <VStack m={0} h="100vh" w="100%" display="flex" alignItems="center" justifyContent="center">
                <HStack>
                    <Text fontSize="5xl"><TbError404 /></Text>
                    <Text fontSize="3xl">|</Text>
                    <Text p={0} fontSize="3xl" fontWeight="bold">Not Found</Text>
                </HStack>
                <Text fontSize="md" fontWeight="medium">The page you're looking for was not found.</Text>
                {/* <Divider w="350px"/> */}
                <Button
                    border="1px"
                    borderRadius="10px"
                    borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                    target="_blank"
                    // bg={colorMode === "dark" ? "#2c313d" : "#CACBCD"}
                    bg="none"
                    _hover={{
                        textDecoration: "none",
                        bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                    }}
                    onClick={() => navigate("/")}
                    leftIcon={<FiHome />}
                >
                    Return To Home
                </Button>
            </VStack>
            <Footer />
        </VStack>
    )
}

export default PageNotFound