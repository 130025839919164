import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from './config/theme';
import BackgroundState from './context/BackgroundState';
import LoaderState from './context/LoaderState';
import About from './pages/About';
import Achievements from './pages/Achievements';
import Education from './pages/Education';
import Experience from './pages/Experience';
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import Projects from './pages/Projects';
import Publications from './pages/Publications';
import Skills from './pages/Skills';
import Volunteering from './pages/Volunteering';
import Background from './components/Background';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <LoaderState>
                <BackgroundState>
                    <Background />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/skills" element={<Skills />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/achievements" element={<Achievements />} />
                            <Route path="/publications" element={<Publications />} />
                            <Route path="/experience" element={<Experience />} />
                            <Route path="/education" element={<Education />} />
                            <Route path="/projects" element={<Projects />} />
                            <Route path="/volunteering" element={<Volunteering />} />

                            {/* Page Not Found */}
                            <Route path='*' element={<PageNotFound />} />
                        </Routes>
                    </BrowserRouter>
                </BackgroundState>
            </LoaderState>
        </ChakraProvider>
    </React.StrictMode>
);
