import vit from "../images/logos/vit.png"
import ornate from "../images/logos/ornate.png"
import thestudy from "../images/logos/thestudy.png"
export const education = [
    {
        id: 0,
        logo: vit,
        name: "Vellore Institute of Technology",
        degree: "B.Tech",
        stream: "Computer Science",
        date: "2020 - 2024",
        location: "Vellore, Tamil Nadu",
        grade: "9.1 CGPA"
    },
    {
        id: 1,
        logo: ornate,
        name: "Ornate Edusystems",
        degree: "12th Board",
        stream: "PCM",
        date: "2018 - 2020",
        location: "Didwana, Rajasthan",
        grade: "96.67 %"
    },
    {
        id: 2,
        logo: thestudy,
        name: "The Study Secondary School",
        degree: "10th Board",
        stream: "All Compulsory Subjects",
        date: "2008 - 2018",
        location: "Didwana, Rajasthan",
        grade: "90.83 %"
    }
]