import { HStack, Stack, Text, VStack, useColorMode, Link, Button, Spacer } from '@chakra-ui/react'
import React from 'react'
import { FiGithub, FiExternalLink } from "react-icons/fi"
import { motion } from 'framer-motion'

const Project = (props) => {
    const { colorMode } = useColorMode()

    return (
        <motion.div
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
                type: "spring",
                duration: props.delay,
            }}
        >
            <motion.div
                whileHover={{
                    scale: 1.05
                }}>
                <Stack
                    direction={{ base: "column", md: "row", }}
                    border="1px"
                    borderRadius="10px"
                    borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                    backdropFilter={"blur(5px)"}
                    width="100%"
                    p={4}>
                    <HStack>
                        <Text fontSize="xl">
                            {props.emoji}
                        </Text>

                        <VStack
                            alignItems="left"
                            spacing={0}
                            p={0}>
                            <Text
                                fontSize="md"
                                fontWeight="bold">
                                {props.name}
                            </Text>
                            <Text
                                color={colorMode === "dark" ? "#939595" : "#848484"}
                                fontSize="sm">
                                {props.description}
                            </Text>
                        </VStack>
                    </HStack>
                    <Spacer />
                    <HStack >

                        {props.website.length !== 0 && <Button
                            border="1px"
                            borderRadius="10px"
                            borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                            as={Link}
                            href={props.website}
                            p={0}
                            w={{ base: "full" }}
                            target="_blank"
                            bg="none"
                            _hover={{
                                textDecoration: "none",
                                bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                            }}>
                            <FiExternalLink />
                        </Button>}


                        {props.github.length !== 0 && <Button
                            border="1px"
                            borderRadius="10px"
                            borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                            as={Link}
                            href={props.github}
                            p={0}
                            w={{ base: "full" }}
                            target="_blank"
                            bg="none"
                            _hover={{
                                textDecoration: "none",
                                bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                            }}>
                            <FiGithub />
                        </Button>}

                    </HStack>
                </Stack>
            </motion.div>
        </motion.div>
    )
}

export default Project