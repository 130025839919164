import { HStack } from '@chakra-ui/react'
import React from 'react'
import socials from '../../data/socials'
import SocialButton from './SocialButton'
import ResumeButton from './ResumeButton'
import ContactButton from './ContactButton'
import { motion } from "framer-motion";

const Socials = () => {
    return (
        <>
            <HStack spacing="0">
                {socials.map((social) => {
                    return <SocialButton key={social.id} social={social} />
                })}
            </HStack>
            <HStack spacing={3}>
                <motion.div
                    whileHover={{
                        y: -2
                    }}>
                    <ContactButton />
                </motion.div>
                <motion.div
                    whileHover={{
                        y: -2
                    }}>
                    <ResumeButton />
                </motion.div>
            </HStack>
        </>
    )
}

export default Socials