import { Box, Link, Button, HStack, Stack, Image, Spacer, Text, VStack, useColorMode, useDisclosure } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'
import React from 'react'
import { FiEye } from "react-icons/fi"
import { motion } from 'framer-motion'
import { FiCalendar } from "react-icons/fi"

const Publication = (props) => {
    const { colorMode } = useColorMode()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const size = props.description === "ICACCS-2023 · IEEE" ? "md" : "3xl"
    return (
        <>
            <Modal
                motionPreset="slideInBottom"
                isOpen={isOpen}
                onClose={onClose}
                size={{ base: "xs", md: "xl", lg: size }}
                isCentered>
                <ModalOverlay
                    backdropFilter='blur(3px)' />
                <ModalContent>
                    <Image
                        borderRadius="8px"
                        src={props.certificate} />
                </ModalContent>
            </Modal>
            <motion.div
                initial={{ y: "100%", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                    type: "spring",
                    duration: props.delay,
                }}
            >
                <motion.div
                    whileHover={{
                        scale: 1.05
                    }}>
                    <Stack
                        direction={{ base: "column", md: "row", }}
                        border="1px"
                        borderRadius="10px"
                        borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                        backdropFilter={"blur(5px)"}
                        width="100%"
                        p={4}>
                        <HStack >
                            {/* <Image
                            mt={1}
                            borderRadius="10px"
                            boxSize="60px"
                            src={props.logo} /> */}
                            <VStack
                                alignItems="left"
                                spacing={0}
                                p={0}>
                                <Link
                                    href={props.link}
                                    target="_blank"
                                    fontSize="md"
                                    fontWeight="bold">
                                    {props.title}
                                </Link>
                                <Text
                                    fontSize="sm"
                                    fontWeight="semibold">
                                    {props.description}
                                </Text>
                                <Text
                                    color={colorMode === "dark" ? "#939595" : "#848484"}
                                    fontSize="sm" display="flex"
                                    alignItems="center">
                                    <FiCalendar />&nbsp;{props.date}
                                </Text>
                            </VStack>
                        </HStack>
                        <Spacer />
                        <Image
                            display={{ base: "none", md: "block" }}
                            onClick={onOpen}
                            style={{ height: "70px", width: "100px" }}
                            borderRadius="5px"
                            _hover={{
                                cursor: "pointer"
                            }}
                            src={props.certificate} />
                        <Box
                            display={{ base: "block", md: "none" }}>
                            <Button
                                border="1px"
                                borderRadius="10px"
                                borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                                as={Link}
                                href={props.website}
                                p={0}
                                w={{ base: "full" }}
                                target="_blank"
                                bg="none"
                                fontWeight="normal"
                                leftIcon={<FiEye />}
                                onClick={onOpen}
                                // bg={colorMode === "dark" ? "#2c313d" : "#CACBCD"}
                                _hover={{
                                    textDecoration: "none",
                                    bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                                }}>
                                View Certificate
                            </Button>
                        </Box>
                    </Stack>
                </motion.div>
            </motion.div>
        </>
    )
}

export default Publication