import tfd from "../images/logos/tfd.jpeg"
import gwoc from "../images/logos/gwoc.jpeg"
import tezos from "../images/logos/tezos.jpeg"
import iet from "../images/logos/iet.jpeg"
import ixda from "../images/logos/ixda.jpeg"
export const volunteering = [
    {
        id: 1,
        role: "App Developer",
        company: "Team Fourth Dimension",
        date: "Dec 2020 - Dec 2022",
        logo: tfd,
        description: ["Mentored junior app developers and managed project workflows for the technical team.", "Developed an Android app to aid students in creating schedules and selecting courses, which has surpassed 1k downloads on the Play Store.", "Acted as a technical speaker at seminars discussing diverse technical subjects and organized events for the Institute of Engineering and Technology (IET) at VIT."]
    },
    {
        id: 2,
        role: "Open Source Contributor",
        company: "GirlScript Winter of Contributing",
        date: "Sep 2021 - Dec 2021",
        logo: gwoc,
        description: ["I have contributed to repositories focused on Android development using Java, web development using HTML, CSS, JavaScript, and Bootstrap, as well as C/C++ programming.", "I have gained hands-on experience and improved my skills in these areas, while also making a positive impact on the open-source community."]
    },
    {
        id: 3,
        role: "Community Member",
        company: "Tezos Developers Community",
        date: "May 2021 - Jan 2022",
        logo: tezos,
        description: ["I actively engaged in various activities focused on learning about Tezos and blockchain technology.", "I gained valuable insights into the potential of blockchain technology and the ways it can transform industries and communities."]
    },
    {
        id: 4,
        role: "Senior Core Committee Member",
        company: "IET VIT",
        date: "Dec 2020 - Dec 2022",
        logo: iet,
        description: []
    },
    {
        id: 5,
        role: "Senior Core Committee Member",
        company: "IxDA VIT",
        date: "Aug 2021 - Mar 2022",
        logo: ixda,
        description: []
    },
]

