import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
    initialColorMode: "dark",
    useSystemColorMode: false,
    fonts: {
        heading: `'Nunito', sans-serif`,
        body: `'Nunito', sans-serif`,
    },
    styles: {
        global: (props) => ({
            'html, body': {
                bg: props.colorMode === "dark" ? "#09090B" : "#f5f5f5",
                color: props.colorMode === "dark" ? "#dadbdb" : "#393e46",
            },
            '&::-webkit-scrollbar': { width: "0.5rem", bg: props.colorMode === "dark" ? "#09090B" : "#f5f5f5" },
            '&::-webkit-scrollbar-track': { bg: "transparent", borderRadius: "3rem" },
            '&::-webkit-scrollbar-thumb': { bg: props.colorMode === "dark" ? "#2a2b2d" : "#CACBCD", borderRadius: "3rem" },
        }),
    },

})

export default theme

// old dark bg => #1A202C / 1D1E20 (if want to change in future)