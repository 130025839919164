// importing logos
import sih from '../images/logos/sih.jpg'
import bhilai from '../images/logos/bhilai.jpeg'
import wehack from '../images/logos/wehack.jpg'
import forktober from '../images/logos/forktober.png'
import appathon from '../images/logos/appathon.jpg'
import devspace from '../images/logos/devspace.png'
import cognition from '../images/logos/cognition.jpeg'
import vit from "../images/logos/vit.png"
import cryptic_hunt from "../images/logos/cryptic_hunt.png"
import hack_upc from "../images/logos/hack_upc.png"
//     {
// importing certificates
import sih_cert from '../images/certificates/smartindiahackathon.png'
import bhilai_cert from '../images/certificates/bhilai.png'
import wehack_cert from '../images/certificates/wehack.png'
import forktober_cert from '../images/certificates/forktober.png'
import appathon_cert from '../images/certificates/appathon.png'
import devspace_cert from '../images/certificates/devspace.png'
import cognition_cert_1 from '../images/certificates/cognition-bestuiux.jpg'
import cognition_cert_2 from '../images/certificates/cognition-openinnovation.jpg'
import achiever_award from "../images/certificates/achiever_award.png"
import cryptic_hunt_22 from "../images/certificates/cryptic_hunt_22.jpg"
import cryptic_hunt_23 from "../images/certificates/cryptic_hunt_23.jpg"
import hack_upc_2021 from "../images/certificates/hack_upc_2021.png"

const achievements = [
    {
        id: 9,
        title: "Winner",
        description: "Cryptic Hunt 2.0 · ACM-VIT",
        certificate: cryptic_hunt_23,
        date: "Sep 2023",
        logo: cryptic_hunt
    },
    {
        id: 10,
        title: "Achiever Award",
        description: "Vellore Institute of Technology",
        certificate: achiever_award,
        date: "May 2023",
        logo: vit
    },
    {
        id: 11,
        title: "Winner",
        description: "Cryptic Hunt · ACM-VIT",
        certificate: cryptic_hunt_22,
        date: "Oct 2022",
        logo: cryptic_hunt
    },
    {
        id: 0,
        title: "Grand Finale Winner",
        description: "Smart India Hackathon · Ministry of Education, Govt. of India",
        certificate: sih_cert,
        date: "Aug 2022",
        logo: sih
    },
    {
        id: 1,
        title: "Winner",
        description: "Bhilai Hacks · BIT",
        certificate: bhilai_cert,
        date: "May 2021",
        logo: bhilai
    },
    {
        id: 1,
        title: "iThink UPC Challenge Winner",
        description: "HackUPC",
        certificate: hack_upc_2021,
        date: "May 2021",
        logo: hack_upc
    },
    {
        id: 2,
        title: "Second Runner Up",
        description: "WE Hack · IEEE WIE-VIT",
        certificate: wehack_cert,
        date: "Apr 2022",
        logo: wehack
    },
    {
        id: 3,
        title: "Runner Up",
        description: "Forktober · ACM-VIT",
        certificate: forktober_cert,
        date: "Oct 2021",
        logo: forktober
    },
    {
        id: 4,
        title: "Second Runner Up",
        description: "App-a-thon · ADG-VIT",
        certificate: appathon_cert,
        date: "Nov 2021",
        logo: appathon
    },
    {
        id: 5,
        title: "Experion Track Winner",
        description: "Devspace · CSI-VIT",
        certificate: devspace_cert,
        date: "Apr 2022",
        logo: devspace
    },
    {
        id: 7,
        title: "Best UI/UX Winner",
        description: "Cognition · VITMAS",
        certificate: cognition_cert_1,
        date: "Aug 2022",
        logo: cognition
    },
    {
        id: 8,
        title: "Open Innovation Track Winner",
        description: "Cognition · VITMAS",
        certificate: cognition_cert_2,
        date: "Aug 2022",
        logo: cognition
    },

]

export default achievements
