import { AspectRatio, Button, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Stack, useColorMode, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { FiDownload, FiExternalLink, FiFileText } from "react-icons/fi"

const ResumeButton = (props) => {
    const { colorMode } = useColorMode()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                scrollBehavior='inside'
                onClose={onClose}
                isOpen={isOpen}
                isCentered
                motionPreset="slideInBottom"
                size={{ lg: "3xl" }}
            >
                <ModalOverlay
                    backdropFilter='blur(3px)' />
                <ModalContent bg={colorMode === "dark" ? "#09090B" : "#f5f5f5"}>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack>
                            <AspectRatio height="70vh" mt={10}>
                                <iframe title="Resume" src="https://drive.google.com/file/d/1GspiR7an2CgK1jM-ttdV2lHlfhtsYEHx/preview" />
                            </AspectRatio>

                        </Stack>
                    </ModalBody>
                    <ModalFooter justifyContent="center" width="100%">
                        <Button
                            width="full"
                            border="1px"
                            borderRadius="10px"
                            onClick={onOpen}
                            borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                            as={Link}
                            href={"https://drive.google.com/file/d/1GspiR7an2CgK1jM-ttdV2lHlfhtsYEHx/view"}
                            target="_blank"
                            // bg={colorMode === "dark" ? "#2c313d" : "#CACBCD"}
                            bg="none"
                            leftIcon={<FiExternalLink />}
                            mr={2}
                            _hover={{
                                textDecoration: "none",
                                bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                            }} >
                            Open
                        </Button>
                        <Button
                            width="full"
                            border="1px"
                            borderRadius="10px"
                            onClick={onOpen}
                            borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                            as={Link}
                            href={"https://drive.google.com/uc?export=download&id=1GspiR7an2CgK1jM-ttdV2lHlfhtsYEHx"}
                            target="_blank"
                            // bg={colorMode === "dark" ? "#2c313d" : "#CACBCD"}
                            bg="none"
                            ml={2}
                            leftIcon={<FiDownload />}
                            _hover={{
                                textDecoration: "none",
                                bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                            }}
                        >
                            Download
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Button
                border="1px"
                borderRadius="10px"
                onClick={onOpen}
                borderColor={colorMode === "dark" ? "#2a2b2d" : "#CACBCD"}
                // as={Link}
                // href={"https://drive.google.com/file/d/1GspiR7an2CgK1jM-ttdV2lHlfhtsYEHx/view"}
                target="_blank"
                // bg={colorMode === "dark" ? "#2c313d" : "#CACBCD"}
                bg="none"
                backdropFilter={"blur(5px)"}
                _hover={{
                    textDecoration: "none",
                    bg: colorMode === "dark" ? "#2a2b2d" : "#e2e8f0"
                }}
                leftIcon={<FiFileText />}>
                Resume
            </Button>
        </>
    )
}

export default ResumeButton