import { useState } from "react";
import BackgroundContext from "./backgroundContext";

const BackgroundState = (props) => {
    const [showBackground, setShowBackground] = useState(true)

    return (
        <BackgroundContext.Provider value={{ showBackground, setShowBackground }}>
            {props.children}
        </BackgroundContext.Provider>
    )
}

export default BackgroundState

