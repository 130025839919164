
import { SiLeetcode, SiGeeksforgeeks } from "react-icons/si"
import { FiGithub, FiLinkedin, FiInstagram, FiTwitter, FiMail } from "react-icons/fi"
const socials = [
    {
        id: 0,
        site: "Linkedin",
        url: "https://linkedin.com/in/prasoonsoni",
        icon: <FiLinkedin />,
        color: "#0077B5"
    },
    {
        id: 1,
        site: "Github",
        url: "https://github.com/prasoonsoni",
        icon: <FiGithub />,
        color:"#171515"
    },
    {
        id: 2,
        site: "Instagram",
        url: "https://instagram.com/prasoonsoni",
        icon: <FiInstagram />,
        color:"#F70269"
    },
    {
        id: 3,
        site: "Twitter",
        url: "https://twitter.com/prasoonsoni_",
        icon: <FiTwitter />,
        color:"#1DA1F2"
    },
    {
        id: 4,
        site: "Mail",
        url: "mailto:prasoonsoni.work@gmail.com",
        icon: <FiMail />,
        color:"#EA4335"
    },
    {
        id: 5,
        site: "Leetcode",
        url: "https://leetcode.com/prasoonsoni",
        icon: <SiLeetcode />,
        color:"#E49E3E"
    },
    // {
    //     id: 6,
    //     site: "Geeks For Geeks",
    //     url: "https://auth.geeksforgeeks.org/user/prasoonsoni",
    //     icon: <SiGeeksforgeeks />,
    //     color:"#2D8642"
    // }
]

export default socials
