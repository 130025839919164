import { SiAmazonaws, SiAnaconda, SiAndroidstudio, SiArduino, SiBootstrap, SiChakraui, SiCplusplus, SiDocker, SiExpress, SiFirebase, SiGit, SiGithub, SiGitlab, SiGnubash, SiGo, SiJava, SiJavascript, SiLinux, SiMaterialui, SiMicrosoftazure, SiMicrosoftedge, SiMongodb, SiMysql, SiNodedotjs, SiPhp, SiPostgresql, SiPostman, SiPython, SiReact, SiSpringboot, SiTailwindcss, SiTypescript, SiWindows } from "react-icons/si"

import { FaBrain } from "react-icons/fa"
import { MdLeaderboard, MdLightbulb, MdMessage } from "react-icons/md"
import { RiTeamFill } from "react-icons/ri"
export const programming_languages = [
    {
        id: 1,
        text: "Java",
        icon: <SiJava />,
        color: "#F0931C"
    },
    {
        id: 2,
        text: "Python",
        icon: <SiPython />,
        color: "#336D9D"
    },
    {
        id: 3,
        text: "C/C++",
        icon: <SiCplusplus />,
        color: "#00417B"
    },
    {
        id: 4,
        text: "JavaScript",
        icon: <SiJavascript />,
        color: "#EAD41C"
    },
    {
        id: 8,
        text: "TypeScript",
        icon: <SiTypescript />,
        color: "#2F72BC"
    },
    {
        id: 9,
        text: "Go",
        icon: <SiGo />,
        color: "#79D4FD"
    },
    {
        id: 5,
        text: "PHP",
        icon: <SiPhp />,
        color: "#7377AD"
    },
    {
        id: 6,
        text: "SQL",
        icon: <SiPostgresql />,
        color: "#30628A"
    },
    {
        id: 7,
        text: "Bash",
        icon: <SiGnubash />,
        color: "#262E34"
    }
]

export const technologies_frameworks = [
    {
        id: 1,
        text: "React.js",
        icon: <SiReact />
    },
    {
        id: 2,
        text: "Node.js",
        icon: <SiNodedotjs />
    },
    {
        id: 3,
        text: "Express.js",
        icon: <SiExpress />
    },
    {
        id: 13,
        text: "Spring Boot",
        icon: <SiSpringboot />
    },
    {
        id: 11,
        text: "AWS",
        icon: <SiAmazonaws />
    },
    {
        id: 4,
        text: "MongoDB",
        icon: <SiMongodb />
    },
    {
        id: 12,
        text: "Azure",
        icon: <SiMicrosoftazure />
    },
    {
        id: 5,
        text: "Firebase",
        icon: <SiFirebase />
    },
    {
        id: 6,
        text: "Chakra UI",
        icon: <SiChakraui />
    },
    {
        id: 7,
        text: "MySQL",
        icon: <SiMysql />
    },
    {
        id: 8,
        text: "Bootstrap",
        icon: <SiBootstrap />
    },
    {
        id: 9,
        text: "Tailwind CSS",
        icon: <SiTailwindcss />
    },
    {
        id: 10,
        text: "Material UI",
        icon: <SiMaterialui />
    }
]

export const developer_tools = [
    {
        id: 1,
        text: "Git",
        icon: <SiGit />
    },
    {
        id: 6,
        text: "GitHub",
        icon: <SiGithub />
    },
    {
        id: 7,
        text: "GitLab",
        icon: <SiGitlab />
    },
    {
        id: 2,
        text: "Android Studio",
        icon: <SiAndroidstudio />
    },
    {
        id: 3,
        text: "Postman",
        icon: <SiPostman />
    },
    {
        id: 4,
        text: "Anaconda",
        icon: <SiAnaconda />
    },
    {
        id: 5,
        text: "Docker",
        icon: <SiDocker />
    }
]

export const platforms = [
    {
        id: 1,
        text: "Linux",
        icon: <SiLinux />
    },
    {
        id: 2,
        text: "Web",
        icon: <SiMicrosoftedge />
    },
    {
        id: 1,
        text: "Windows",
        icon: <SiWindows />
    },
    {
        id: 1,
        text: "Arduino",
        icon: <SiArduino />
    }
]

export const soft_skills = [
    {
        id: 1,
        text: "Communication",
        icon: <MdMessage />
    },
    {
        id: 2,
        text: "Organizational Leadership",
        icon: <MdLeaderboard />
    },
    {
        id: 3,
        text: "Analytical Thinking",
        icon: <MdLightbulb />
    },
    {
        id: 4,
        text: "Teamwork",
        icon: <RiTeamFill />
    },
    {
        id: 5,
        text: "Problem Solving",
        icon: <FaBrain />
    }
]