import samsung from '../images/logos/samsung.jpeg'
import scp from '../images/logos/scp.png'
import chaibazaar from '../images/logos/chaibazaar.jpeg'
import honeywell from '../images/logos/honeywell.jpeg'
import chegg from '../images/logos/chegg.jpeg'
import powstik from "../images/logos/powstik.jpeg"
import accenture from "../images/logos/accenture.png"
import aihyr from "../images/logos/aihyr.png"
import wellsfargo from "../images/logos/wellsfargo.jpeg"
export const experience = [
    {
        id: 9,
        role: "Program Associate",
        company: "Wells Fargo",
        type: "Full-time",
        location: "Hyderabad, India",
        date: "Feb 2024 - Jun 2024",
        logo: wellsfargo,
        description: ["Working in consumer lending technology line of business."]
    },
    {
        id: 9,
        role: "Analyst",
        company: "Wells Fargo",
        type: "Internship",
        location: "Hyderabad, India",
        date: "Feb 2024 - Jun 2024",
        logo: wellsfargo,
        description: [
            "Developed and Enhanced a Comprehensive Tool: Utilized Java, Spring Boot, and MongoDB to build a release version tool that gathers extensive release evidence, including target versions, reference versions, configuration drifts, and Jira-related details.",
            "Integrated Multiple Platforms: Implemented features for integration with source code platforms like GitHub and TFS, and CI/CD platforms such as UCD, Harness, TFS, and Kubernetes Helm.",
            "Advanced Data Export Capabilities: Added functionality to export data in PDF and Excel formats, significantly enhancing reporting capabilities.",
            "Security and Quality Metrics: Incorporated features to display ThreadFix vulnerabilities and Sonar metrics, ensuring high standards of security and code quality.",
            "Efficiency Improvements: Decreased manual efforts by 50%, providing real-time data needed for the release process and reducing the time required for release preparation by 30%.",
            "Active Development Role: Contributed to the tool's development, ensuring accuracy, efficiency, and reliability in collecting and presenting crucial release data.",
            "Global Technology Hackathon 2024: Participated in the Global Technology Hackathon organized by Wells Fargo.",
            "Finalist Achievement: Reached the finalist stage for the problem statement 'Best Resume Match' showcasing innovative solutions for resume and job matching.",
        ]
    },
    {
        id: 8,
        role: "Software Engineer",
        company: "AI.HYR",
        type: "Internship",
        location: "Remote",
        date: "Aug 2023 - Feb 2024",
        logo: aihyr,
        description: [
            "Developed end-to-end candidate interview solutions, including platforms for conducting interviews and coding tests.",
            "Engineered systems for bulk sending of interview invitations and customized interview setups for diverse company needs.",
            "Implemented real-time evaluation of candidate responses to enhance decision-making processes.",
            "Established automated cron jobs to ensure continuous operational excellence and timely issue resolution in the interview process.",
            "Designed and implemented environment check setups, including camera, mic, and internet speed tests, to ensure optimal interview conditions.",
            "Collaborated with Washington University startup 'SMASH' to create a video interview-based questionnaire, capturing user input through audio and multiple-choice questions, and extracting relevant data from responses.",
            "Founding Engineer, pioneering advancements in recruitment technology.",
            "Optimised recruitment procedures, enhancing workflow efficiency and operational effectiveness.",
            "Implemented performance-driven hiring strategies to align recruitment efforts with organizational goals.",
            "Automated recruitment processes, reducing human involvement by over 80% and achieving significant cost savings.",
            "Accelerated time-to-interview by under 40% and expedited time-to-hire by less than 50% through streamlined automation.",
            "Lowered recruitment expenses by less than 30% through efficient resource allocation and process optimization.",

        ]

    },
    {
        id: 7,
        role: "Advanced App Engineering Analyst",
        company: "Accenture",
        type: "Internship",
        location: "Remote",
        date: "Jun 2023 - Jul 2023",
        logo: accenture,
        description: ["Worked on a project related to AWS Security for managing the client's AWS resources.", "Got hands on experience of AWS Services like EC2, S3, IAM, Lambda and RDS.", "Conducted thorough research on Quantum Computing and gave a talk on Post Quantum Cryptography."]
    },
    {
        id: 1,
        role: "Research Intern",
        company: "Samsung R&D Institute India",
        type: "Internship",
        location: "Remote",
        date: "Dec 2022 - Jun 2023",
        logo: samsung,
        description: ["Working on developing a Transport Capsule with Government’s APIsetu apis for Bixby to leverage existing APIs and build rich conversational experiences.", "Understanding the Bixby Ecosystem, which is the Samsung’s Voice Assistant."]
    },
    {
        id: 6,
        role: "Full Stack Developer",
        company: "Powstik",
        type: "Internship",
        location: "Vellore",
        date: "Mar 2023 - Jun 2023",
        logo: powstik,
        description: ["Migrated WordPress backend from PHP-MySQL to Node.js-Express.js and MongoDB.", "Conducted thorough APIs testing and debugging using Postman to ensure backend functionality and reliability.", "Implemented payment gateway on checkout page using Razorpay SDK."]
    },

    {
        id: 2,
        role: "Full Stack Developer",
        company: "Spark Consultancy Pro",
        type: "Internship",
        location: "Remote",
        date: "Jun 2022 - Nov 2022",
        logo: scp,
        description: ["Deployed and maintained websites on Amazon Web Services (AWS).", "Maintained and created websites using MERN Stack.", "Created a custom WhatsApp API to send website form responses to clients through WhatsApp chat."]
    },
    {
        id: 3,
        role: "Android Developer",
        company: "ChaiBaazaar",
        type: "Internship",
        location: "Remote",
        date: "Sep 2021",
        logo: chaibazaar,
        description: ["Led the development and maintenance of an Android app from the beginning stages.", "Implemented application logic using Java and Android Studio, using MVVM architecture.", "Developed rich UI/UX for the application modules using Material UI."]
    },
    {
        id: 4,
        role: "Mentee",
        company: "Honeywell",
        type: "Apprenticeship",
        location: "Remote",
        date: "May 2022 - Aug 2022",
        logo: honeywell,
        description: ["Participated in an Accelerator Program to gain hands-on experience in solving industry-specific problems through software-based solutions.", "As a member of a team, successfully worked on a project to develop a solution for automatically generating test cases from text conversations."]
    },
    {
        id: 5,
        role: "Managed Network Expert",
        company: "Chegg India",
        type: "Freelance",
        location: "Remote",
        date: "Mar 2022 - May 2023",
        logo: chegg,
        description: ["Working as a freelancer, providing solutions to textbook questions and answering queries from users on Chegg.", "Successfully completed the milestone of solving 100 questions on Chegg's Q&A Dashboard."]
    },

]

