import { VStack, Heading, Text } from "@chakra-ui/react";
import ProfileImage from "../components/Home/ProfileImage";
import Socials from "../components/Home/Socials"
// import Typing from "../components/Home/Typing"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Loader from "../components/Loader/Loader"
import { useEffect, useContext } from "react";
import loaderContext from "../context/loaderContext";
import TextAnimation from "../components/Home/TextAnimation";
function App() {
    const context = useContext(loaderContext)
    const { loading, setLoading } = context
    useEffect(() => {
        document.title = "Prasoon Soni"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [setLoading])

    return (
        <VStack h="100vh" spacing={0}>
            {loading ? <Loader /> : <><Navbar />
                <VStack m={0} h="100vh" w="100%" display="flex" alignItems="center" justifyContent="center">
                    <VStack display="flex" justifyContent="center" alignItems="center" p={4} borderRadius={10}>
                        <ProfileImage />
                        <Heading >Prasoon Soni</Heading>
                        {/* <Text fontSize="xl"><Typing /></Text> */}
                        <Text fontSize="xl" fontWeight="light"><TextAnimation /></Text>

                        <Socials />
                    </VStack>
                </VStack>
                <Footer /></>}
        </VStack>
    );
}

export default App;
