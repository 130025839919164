import React, { useEffect, useContext } from 'react'
import Navbar from "../components/Navbar"
import { VStack, Heading } from '@chakra-ui/react'
import Section from '../components/Section'
import achievements from '../data/achievements'
import Achievement from '../components/Achievements/Achievement'
import loaderContext from "../context/loaderContext";
import Loader from '../components/Loader/Loader'
const Achievements = () => {
    const context = useContext(loaderContext)
    const { loading, setLoading } = context

    useEffect(() => {
        document.title = "Achievements | Prasoon Soni"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [setLoading])
    return (
        <div>
            {loading ? <Loader /> : <>
                <Navbar />
                <Section id="achievement">
                    <VStack align="stretch" spacing={4} p={4}>
                        <Heading w="full">Achievements</Heading>
                        {achievements.map((achievement, i) => {
                            return <Achievement
                                key={achievement.id}
                                title={achievement.title}
                                description={achievement.description}
                                logo={achievement.logo}
                                certificate={achievement.certificate}
                                date={achievement.date}
                                // aos={((i + 1) * 100) + 500}
                                // anchor="#achievement"
                                delay={(i + 1) / 3}
                            />
                        })}

                    </VStack>
                </Section>
            </>}
        </div >
    )
}

export default Achievements